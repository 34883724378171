import React from "react"
import "./comparison.scss"



const Comparison = ({ props }) => {

    return (
        <div className="padding">
            <div className="how-works ">
                {
                    props.title ?
                        <h2 className="how-works__card-title">{props.title}</h2>
                        : ""
                }
                <div className="how-works__container uk-child-width-1-3@s uk-grid-small uk-margin-remove uk-text-center" data-uk-grid>
                    {
                        props.comparison.map((item, index) => {
                            return (

                                <div className="how-works__container_item" key={index}>
                                    <img src={item.image} alt="How does it work" className="item-image"/>
                                    <div className="item-description uk-grid uk-grid-collapse">
                                        <div className="item-description__text uk-width-5-6">{item.info}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )

}

export default Comparison